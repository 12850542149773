import Button from "@mui/material/Button";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Store from "../store/store";

type PropsType = { store: Store };
export const LoginPage: React.FC<PropsType> = observer(
  ({ store }: PropsType) => {
    const navigate = useNavigate();

    useEffect(() => {
      if (store.isAuth) {
        navigate("/users");
      }
    }, [store.isAuth]);

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    return (
      <div className="hero is-fullheight">
        <div className="hero-body is-justify-content-center is-align-items-center">
          <div className="columns is-flex is-flex-direction-column box">
            LOGIN
            <div className="column">
              <label>Email</label>
              <input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="text"
                className="input is-primary"
                placeholder="Email address"
              />
            </div>
            <div className="column">
              <label>Password</label>
              <input
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                type="password"
                className="input is-primary"
                placeholder="Password"
              />
            </div>
            <div className="column">
              <Button
                className="button is-primary is-fullwidth"
                type="submit"
                onClick={() => store.login(email, password)}
                variant="text"
              >
                Login
              </Button>
            </div>
            <div className="has-text-centered"></div>
          </div>
        </div>
      </div>
    );
  }
);
