import $api from "../http";
import { AxiosResponse } from "axios";
import { AuthResponse } from "../models/response/AuthResponse";
import { IUser } from "../models/IUser";

export default class UserService {
  static fetchUsers(): Promise<AxiosResponse<IUser[]>> {
    return $api.get<IUser[]>("/users");
  }

  // static async editUsers(id:string, name:string,email: string, password: string, greenHouses: Array<{
  //     name: string;
  //     serverUrl: string;
  //     GhId: string;
  //   }>):
  //    Promise<AxiosResponse<AuthResponse>> {
  //     return $api.patch<AuthResponse>(`/users/edit/${id}`, {name,email, password, greenHouses})
  // }
  static async addUsers(
    name: string,
    email: string,
    password: string
  ): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>("/users/create", { name, email, password });
  }
  static async deleteUser(id: string): Promise<void> {
    return $api.delete(`/users/${id}`);
  }
}
