import { Box, Modal, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import $api from "../http";
import { GreenHouseType } from "../models/IGreenHouse";

const EditUser = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [greenHouses, setGreenHouses] = useState<GreenHouseType>([]);
  const { id } = useParams();

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    color: "black",
  };

  const [open, setOpen] = React.useState(false);
  const [serverId, setServerId] = React.useState<number | null>(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getUserById = async () => {
    const response = await $api.get(`/users/${id}`);
    setName(response.data.name);
    setEmail(response.data.email);
    setGreenHouses(response.data.greenHouses);
    setPassword(response.data.password);
  };

  useEffect(() => {
    getUserById();
  }, []);

  const updateUser = async (e: any) => {
    try {
      $api.patch(`/users/edit/${id}`, {
        name,
        email,
        password,
        greenHouses,
      });
      alert("User updated");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="columns mt-5 is-centered">
      <div className="column is-half">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="field">
            <label className="label">Name</label>
            <div className="control">
              <input
                type="text"
                className="input"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Email</label>
            <div className="control">
              <input
                type="text"
                className="input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">password</label>
            <div className="control">
              <input
                type="text"
                className="input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="password"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">GreenHouses</label>
            <button
              className="button is-primary"
              type="button"
              onClick={() =>
                setGreenHouses([...greenHouses, { name: "", id: "" }])
              }
            >
              Add new item
            </button>
            <div className="control">
              <table className="table is-striped is-fullwidth mt-2">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>ID</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {greenHouses.map((gh, i) => (
                    <tr key={i}>
                      <td>
                        <input
                          type="text"
                          className="input"
                          value={gh.name}
                          onChange={(e: any) => {
                            setGreenHouses(
                              greenHouses.map((g, index) =>
                                i === index ? { ...g, name: e.target.value } : g
                              )
                            );
                          }}
                          placeholder="name"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="input"
                          value={gh.id}
                          onChange={(e: any) => {
                            setGreenHouses(
                              greenHouses.map((g, index) =>
                                i === index ? { ...g, GhId: e.target.value } : g
                              )
                            );
                          }}
                          placeholder="server id"
                        />
                      </td>
                      <td>
                        <button
                          className="button is-danger is-small"
                          onClick={() => {
                            handleOpen();

                            setServerId(i);
                          }}
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    You want to remove this server?????
                    <button
                      className="button is-primary is-centered"
                      onClick={() => {
                        setGreenHouses([
                          ...greenHouses.filter(
                            (g, index) => serverId !== index
                          ),
                        ]);

                        handleClose();
                      }}
                    >
                      yes
                    </button>
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2 }}
                  ></Typography>
                </Box>
              </Modal>
            </div>
          </div>
          <div className="field">
            <div className="control">
              <button
                type="button"
                onClick={updateUser}
                className="button is-success"
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUser;
