import axios from "axios";
import { AuthResponse } from "../models/response/AuthResponse";

export const API_URL = `${process.env.REACT_APP_SERVER_URL}/api`;

const $api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

$api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
});

$api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    const token = localStorage.getItem("token");
    try {
      // if (
      //   error.response.status === 401 &&
      //   error.config &&
      //   !error.config._isRetry &&
      //   token
      // ) {
      //   originalRequest._isRetry = true;
      //   const response = await $api.get<AuthResponse>(`/refresh/${token}`);
      //   localStorage.setItem("token", response.data.accessToken);
      //   localStorage.setItem("refreshToken", response.data.refreshToken);
      //   return $api.request(originalRequest);
      // }
    } catch (e) {
      console.log("НЕ АВТОРИЗОВАН");
    }
  }
);

export default $api;
