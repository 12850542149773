import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Users from "./components/users";
import { Route, Routes, useNavigate } from "react-router";
import { LoginPage } from "./components/login";
import Store from "./store/store";
import AddUser from "./components/AddUsr";
import EditUser from "./components/editUsers";
import MainApp from "./main.hoc";

export const App: React.FC = observer(() => {
  const [store] = useState(new Store());
  const navigate = useNavigate();

  useEffect(() => {
    store.checkAuth();
  }, [store]);

  return (
    <div>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        {store.isAuth && (
          <div
            style={{ marginTop: 20 }}
            id="navbarBasicExample"
            className="navbar-menu"
          >
            <div className="navbar-start">
              <button
                className="navbar-item button is-primary"
                onClick={() => {
                  navigate("/users");
                }}
              >
                Users
              </button>
            </div>

            <div className="navbar-end">
              <button
                className=" navbar-item button is-primary"
                onClick={() => {
                  store.logout();
                  navigate("/login");
                }}
              >
                Logout
              </button>
            </div>
          </div>
        )}
      </nav>
      <Routes>
        <Route path={"/users"} element={<Users store={store} />} />
        <Route path={"/login"} element={<LoginPage store={store} />} />
        <Route path={"/users/add"} element={<AddUser />} />
        <Route path={"/users/edit/:id"} element={<EditUser />} />
        <Route path={"/"} element={<LoginPage store={store} />} />
      </Routes>
    </div>
  );
});

export default MainApp(App);
