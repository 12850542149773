import { Box, Button, Modal, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import $api from "../http";
import { IUser } from "../models/IUser";
import UserService from "../services/UserService";
import Store from "../store/store";

type PropsType = { store: Store };
const Users: React.FC<PropsType> = observer(({ store }: PropsType) => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    color: "black",
  };

  const [open, setOpen] = React.useState(false);
  const [userId, setUserId] = React.useState<string | null>(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (!store.isAuth) {
    navigate("/login");
  }
  async function getUsers() {
    try {
      const response = await UserService.fetchUsers();
      setUsers(response.data);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getUsers();
  }, []);

  const deleteUser = async (id: string) => {
    try {
      await UserService.deleteUser(id);
      getUsers();
    } catch (error) {
      console.log(error);
    }
  };
  if (store.isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      {/* <div key={user.email}>{user.email}</div> */}
      <div className="columns mt-5 is-centered">
        <div className="column is-half">
          <Link to="add" className="button is-success">
            Add New
          </Link>
          <table className="table is-striped is-fullwidth mt-2">
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Email</th>

                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={user._id}>
                  <td>{index + 1}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>
                    <Link
                      to={`edit/${user._id}`}
                      className="button is-info is-small mr-1"
                    >
                      Edit
                    </Link>
                    <Button
                      onClick={() => {
                        handleOpen();
                        setUserId(user._id);
                      }}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                You want to delete this user?????
                <button
                  className="button is-primary is-centered"
                  onClick={() => {
                    if (userId) {
                      deleteUser(userId);
                    }
                    handleClose();
                  }}
                >
                  yes
                </button>
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2 }}
              ></Typography>
            </Box>
          </Modal>
        </div>
      </div>
    </>
  );
});

export default Users;
